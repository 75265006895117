import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { CSSTransition } from "react-transition-group"
import { SlideDown } from "react-slidedown"
import { Link } from "gatsby"
import "react-slidedown/lib/slidedown.css"

import styles from "src/css/fp-new.module.css"
import mMenuStyles from "src/css/mobile_menu.module.css"
import urls from "../content/urls"

import smallArrow from "src/images/smallArrow.svg"


class MobileMenu extends React.Component {
  constructor(props) {
    super(props)

    this.prevScrollPos = undefined

    this.stateList = []
    this.posList = []


    this.state = {
      visible: true,
      shown: false,
      contactShown: false,

    }

    this.changeShown = this.changeShown.bind(this)
    this.changeContactShown = this.changeContactShown.bind(this)
  }

  componentWillReceiveProps_off(nextProps, nextContext) {
    if (nextProps.scrollPos.scrollPos >= 0) {
      if (this.prevScrollPos === undefined) this.prevScrollPos = nextProps.scrollPos.scrollPos
      else {
        if (this.prevScrollPos > nextProps.scrollPos.scrollPos) {
          // scrolling up
          // console.log("scrolling up")
          if (!this.state.visible) {
            this.setState({ ...this.state, visible: true })
            this.posList.push("u" + nextProps.scrollPos.scrollPos)
          }
        }
        else if (this.prevScrollPos < nextProps.scrollPos.scrollPos) {
          // scrolling down
          // console.log("scrolling down")
          if (this.state.visible) {
            this.setState({ ...this.state, visible: false })
            this.posList.push("d" + nextProps.scrollPos.scrollPos)
          }
        }
        this.prevScrollPos = nextProps.scrollPos.scrollPos
      }
    }
  }

  changeShown() {
    this.setState({
      ...this.state,
      shown: !this.state.shown,
      contactShown: false,
    })
  }

  changeContactShown() {
    this.setState({
      ...this.state,
      shown: false,
      contactShown: !this.state.contactShown,
    })
  }

  render() {
    if (this.stateList[this.stateList.length - 1] !== this.state.visible) this.stateList.push(this.state.visible)
    return (
      <>
        <div className={mMenuStyles.mobile_menu_wrapper}>
          <div className={mMenuStyles.mobile_menu_box}>
            {this.props.homeBack ? (
              <Link to={urls.pl.index} className={mMenuStyles.home_back_link}>
                <div className={mMenuStyles.home_back}>
                  <img src={smallArrow}/>
                  <span>Strona&nbsp;głowna</span>
                </div>
              </Link>
            ) : null}
            <div className={mMenuStyles.mobile_menu}>
              <div className={[mMenuStyles.menu_item, mMenuStyles.our_specialtis].join(" ")} onClick={this.changeShown}>
                Nasze specjalności
              </div>
              <div className={[mMenuStyles.menu_item, mMenuStyles.contact].join(" ")} onClick={this.changeContactShown}>
                Kontakt
              </div>
            </div>
            <div className={[mMenuStyles.menu_item_content, mMenuStyles.our_specialtis_menu_item_content].join(" ")}>
              <SlideDown className={"my-dropdown-slidedown"}>
                {this.state.shown ? (
                  <>
                    <div className={mMenuStyles.menu_item_content_box}>
                      <div className={mMenuStyles.submenu_section}>
                        <div className={mMenuStyles.submenu_item}>
                          <Link to={urls.pl.branding} activeClassName="active">Stworzenie marki</Link>
                        </div>
                      </div>
                      <div className={mMenuStyles.submenu_section}>
                        <div className={mMenuStyles.submenu_item}>
                          <Link to={urls.pl.gadgets} activeClassName="active">Gadżety</Link>
                        </div>
                        <div className={mMenuStyles.submenu_item}>
                          <Link to={urls.pl.papers} activeClassName="active">Ulotki / Wizytówki</Link>
                        </div>
                        <div className={mMenuStyles.submenu_item}>
                          <Link to={urls.pl.outside} activeClassName="active">Reklama zewnętrzna</Link>
                        </div>
                      </div>
                      <div className={mMenuStyles.submenu_section}>
                        <div className={mMenuStyles.submenu_item}>
                          <Link to={urls.pl.marketing} activeClassName="active">Kampanie reklamowe</Link>
                        </div>
                        <div className={mMenuStyles.submenu_item}>
                          <Link to={urls.pl.websites} activeClassName="active">Strony WWW</Link>
                        </div>
                        <div className={mMenuStyles.submenu_item}>
                          <Link to={urls.pl.socialmedia} activeClassName="active">Opieka Social Media</Link>
                        </div>
                      </div>
                      <div className={mMenuStyles.submenu_section}>
                        <div className={mMenuStyles.submenu_item}>
                          <Link to={urls.pl.wideo} activeClassName="active">Wideo</Link>
                        </div>
                        <div className={mMenuStyles.submenu_item}>
                          <Link to={urls.pl.wrapping} activeClassName="active">Oznakowanie pojazdów</Link>
                        </div>
                        <div className={mMenuStyles.submenu_item}>
                          <Link to={urls.pl.printing} activeClassName="active">Wydruki wielkoformatowe</Link>
                        </div>
                      </div>
                      <div className={mMenuStyles.submenu_section}>
                        <div className={mMenuStyles.submenu_item}>
                          <Link to={urls.pl.it} activeClassName="active">Zaawansowane strony WWW</Link>
                        </div>
                        <div className={mMenuStyles.submenu_item}>
                          <Link to={urls.pl.it} activeClassName="active">Systemy firmowe</Link>
                        </div>
                        <div className={mMenuStyles.submenu_item}>
                          <Link to={urls.pl.it} activeClassName="active">Aplikacje mobilne</Link>
                        </div>
                      </div>
                    </div>
                    <div className={mMenuStyles.menu_item_content_spacer}/>
                  </>
                ) : null}
              </SlideDown>
            </div>
            <div className={[mMenuStyles.menu_item_content, mMenuStyles.contact_menu_item_content].join(" ")}>
              <SlideDown className={"my-dropdown-slidedown"}>
                {this.state.contactShown ? (
                  <>
                    <div className={mMenuStyles.menu_item_content_box}>
                      <div>Departament Reklamy</div>
                      <div>AST Bednarscy</div>
                      <div>ul. Widokowa 25</div>
                      <div>26-050 Zagnańsk</div>
                      <div style={{ paddingTop: ".5rem" }}>Os. Na Stoku 72K</div>
                      <div>25-437 Kielce, ŚW, Polska</div>
                      <div style={{ paddingTop: ".5rem" }}>+48 502 551 001</div>
                      <div>+48 512 091 087</div>
                      <div>kontakt@departamentreklamy.pl</div>
                    </div>
                    <div className={mMenuStyles.menu_item_content_spacer}/>
                  </>
                ) : null}
              </SlideDown>
            </div>
          </div>
        </div>
      </>
    )
  }
}

MobileMenu.propTypes = {
  homeBack: PropTypes.bool,
}

MobileMenu.defaultProps = {
  homeBack: false,
}

export default connect(state => ({
  scrollPos: state.scrollPos,
}), null)(MobileMenu)
