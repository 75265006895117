import mt_man from "src/images/pages/videos/mt-man-thumb.jpg"
import mt_targi from "src/images/pages/videos/mt-min.jpg"
import adrenalina from "src/images/pages/videos/adre-thumb.jpg"
import mt_spot from "src/images/pages/videos/mt_spot.jpg"

import promo from "src/images/pages/videos/wojewodztwo-promo.jpg"
import slask from "src/images/pages/videos/slask.jpg"
import wojna from "src/images/pages/videos/wojna.jpg"
import listopada11 from "src/images/pages/videos/11listopada.jpg"
import panstw10 from "src/images/pages/videos/10panstw.jpg"


function videoDataSchem(title, thumbnail, vid, ratio) {
  return {
    title: title,
    thumbnail: thumbnail,
    ratio: ratio,
    vid: vid,
  }
}


export const videos = [
  videoDataSchem("MotoTruck Targi 2019", mt_targi, "1mx18fP9kq8", 1920/816),
  videoDataSchem("Adrenalina", adrenalina, "nnNotfhEaXM", 16/9),
  videoDataSchem("MotoTruck MAN - prezentacja", mt_man, "l0nS6K0L8_c", 16/9),
  videoDataSchem("Spot promocyjny", mt_spot, "TxovNpRV3pI", 16/9),
]

export const videosGlobalista = [
  videoDataSchem("Promocja serii", promo, "xcLsaoddS1s", 1920/816),
  videoDataSchem("Gospodarka województwa śląskiego", slask, "dqxsiQyHp6I", 16/9),
  videoDataSchem("Polityka globalna", wojna, "erFNazbzhrU", 16/9),
  videoDataSchem("Państwa, które ŚWIĘTOWAŁY NASZĄ NIEPODLEGŁOŚĆ", listopada11, "Yj93NViMF5E", 16/9),
  videoDataSchem("10 PAŃSTW, które mogą niedługo ZNIKNĄĆ", panstw10, "Me1mdHiteNE", 16/9),
]
