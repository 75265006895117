import React from "react"
import { connect } from "react-redux"
import { Link as ScrollLink } from "react-scroll"
import { Link } from "gatsby"
import ReactPlayer from "react-player"
import YouTube from "react-youtube"


import SEO from "src/components/seo"
import Layout from "src/components/layout"
import Footer from "src/components/footer"
import MobileMenu from "src/components/mobile_menu"

import subpageStyles from "src/css/subpage.module.css"
import videoSubpageStyles from "src/css/video_subpage.module.css"

import urls from "src/content/urls"
import { videos, videosGlobalista } from "src/content/videos"

import smallArrow from "src/images/smallArrow.svg"

import videoBg from "src/images/pages/videos/videoBg.mp4"
import deplix from "src/images/pages/videos/deplix.png"
import droneBlack from "src/images/pages/videos/drone_black2.jpg"
import droneSky from "src/images/pages/videos/drone-sky.jpg"
import tablica from "../../images/pages/printing/render5.png"
import SubpageLayout from "../../components/subpage_layout"


const PlayerWrapper = ({ children }) => (
  <div className={videoSubpageStyles.video_player}>
    {children}
    <div className={videoSubpageStyles.player_mask}/>
  </div>
)


class VideoPlayerBg extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      fillByWidth: true,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let windowRatio = nextProps.windowW.windowW / nextProps.windowH.windowH
    let videoRatio = 16 / 9

    let fillByWidth = windowRatio >= videoRatio

    this.setState({ ...this.state, fillByWidth })
  }

  render() {
    console.log(this.state)
    return (
      <ReactPlayer playing={true} controls={false} url={videoBg} loop={true}
                   width={this.state.fillByWidth ? "100vw" : "auto"}
                   height={this.state.fillByWidth ? "auto" : "100vh"}
                   muted={true}
                   wrapper={PlayerWrapper}
      />
    )
  }
}


const VideoPlayerBgRed = connect(state => ({
  windowW: state.windowW,
  windowH: state.windowH,
}), null)(VideoPlayerBg)


class VideoPage extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      previewOpened: false,
      previewVideoData: undefined,
    }
    this.target = undefined

    this.openPreview = this.openPreview.bind(this)
    this.closePreview = this.closePreview.bind(this)
    this.pausePreviewPlayer = this.pausePreviewPlayer.bind(this)
    this._onReady = this._onReady.bind(this)
  }

  openPreview(video_data) {
    console.log("open")
    this.setState({
      ...this.state, previewOpened: true, previewVideoData: video_data,
    })
  }

  closePreview() {
    this.setState({
      ...this.state, previewOpened: false, previewVideoData: undefined,
    })
    this.pausePreviewPlayer()
  }

  pausePreviewPlayer() {
    this.target.pauseVideo()
  }

  _onReady(event) {
    this.target = event.target
  }

  render() {
    let previewStyles = {
      display: this.state.previewOpened ? "block" : "none",
    }
    let previewPlayerOpts = {}

    if (this.state.previewVideoData !== undefined) {

      console.log(this.props.windowRatio.windowRatio, this.state.previewVideoData.ratio)
      if (this.props.windowRatio.windowRatio < this.state.previewVideoData.ratio) {
        previewPlayerOpts.width = this.props.windowW.windowW - 50
        previewPlayerOpts.height = previewPlayerOpts.width / this.state.previewVideoData.ratio
      } else {
        previewPlayerOpts.height = this.props.windowH.windowH - 50
        previewPlayerOpts.width = previewPlayerOpts.height * this.state.previewVideoData.ratio
      }

      console.log("opts", previewPlayerOpts)
    }

    return (
      <Layout style={{ backgroundColor: "black" }}>
        <SEO title="Wideo, Spoty reklamowe, Nagrywanie filmów, Dron - Departament Reklamy"
             keywords={[`departament reklamy`, `elo`, `react`]} lang="pl"/>
        <VideoPlayerBgRed/>

        <div style={previewStyles}>
          <div className={videoSubpageStyles.video_preview_bg} onClick={() => {
            this.closePreview()
          }}>
            <div className={videoSubpageStyles.video_preview_player_wrapper}>
              <div className={videoSubpageStyles.video_preview_player}>
                {this.state.previewVideoData !== undefined ? (
                  <YouTube
                    videoId={this.state.previewVideoData.vid}
                    onReady={this._onReady}
                    opts={previewPlayerOpts}
                  />
                ) : (
                  <YouTube
                    videoId={""}
                    onReady={this._onReady}
                  />
                )}
              </div>
            </div>
          </div>
        </div>


        {this.props.isMobile.isMobile ? (
          <MobileMenu homeBack={true}/>
        ) : null}


        {/*MENU*/}
        <div className={videoSubpageStyles.fp_menu}>
          <div className={videoSubpageStyles.menu_content_wrapper}>
            <div className={[videoSubpageStyles.menu_bg, videoSubpageStyles.hidden].join(" ")}/>
            <div className={videoSubpageStyles.menu_content}>
              <div>
                <div>
                  <img src={deplix} className={videoSubpageStyles.deplix_logo}/>
                </div>
                {this.props.isMobile.isMobile ? null : (
                  <div className={[videoSubpageStyles.menu_item, videoSubpageStyles.back_home_item].join(" ")}>
                    <div className={videoSubpageStyles.box}>
                      <img src={smallArrow}/>
                      <Link to={urls.pl.index} className={videoSubpageStyles.text}>Strona&nbsp;głowna</Link>
                    </div>
                  </div>
                )}
              </div>
              {this.props.isMobile.isMobile ? null : (
                <div>
                  <div className={[videoSubpageStyles.menu_item, videoSubpageStyles.dropdown].join(" ")}>
                    <div className={videoSubpageStyles.box}>
                      <img src={smallArrow}/>
                      <div className={videoSubpageStyles.text}>Nasze&nbsp;specjalności</div>
                    </div>
                    <div className={videoSubpageStyles.submenu}>
                      <div className={videoSubpageStyles.submenu_title}>Nasze specjalności</div>
                      <div className={videoSubpageStyles.submenu_section}>
                        <div className={videoSubpageStyles.submenu_item}>
                          <Link to={urls.pl.branding} activeClassName="active">Stworzenie marki</Link>
                        </div>
                      </div>
                      <div className={videoSubpageStyles.submenu_section}>
                        <div className={videoSubpageStyles.submenu_item}>
                          <Link to={urls.pl.gadgets} activeClassName="active">Gadżety</Link>
                        </div>
                        <div className={videoSubpageStyles.submenu_item}>
                          <Link to={urls.pl.papers} activeClassName="active">Ulotki / Wizytówki</Link>
                        </div>
                        <div className={videoSubpageStyles.submenu_item}>
                          <Link to={urls.pl.outside} activeClassName="active">Reklama zewnętrzna</Link>
                        </div>
                      </div>
                      <div className={videoSubpageStyles.submenu_section}>
                        <div className={videoSubpageStyles.submenu_item}>
                          <Link to={urls.pl.marketing} activeClassName="active">Kampanie reklamowe</Link>
                        </div>
                        <div className={videoSubpageStyles.submenu_item}>
                          <Link to={urls.pl.websites} activeClassName="active">Strony WWW</Link>
                        </div>
                        <div className={videoSubpageStyles.submenu_item}>
                          <Link to={urls.pl.socialmedia} activeClassName="active">Opieka Social Media</Link>
                        </div>
                      </div>
                      <div className={videoSubpageStyles.submenu_section}>
                        <div className={videoSubpageStyles.submenu_item}>
                          <Link to={urls.pl.wideo} activeClassName="active">Wideo</Link>
                        </div>
                        <div className={videoSubpageStyles.submenu_item}>
                          <Link to={urls.pl.wrapping} activeClassName="active">Oznakowanie pojazdów</Link>
                        </div>
                        <div className={videoSubpageStyles.submenu_item}>
                          <Link to={urls.pl.printing} activeClassName="active">Wydruki wielkoformatowe</Link>
                        </div>
                      </div>
                      <div className={videoSubpageStyles.submenu_section}>
                        <div className={videoSubpageStyles.submenu_item}>
                          <Link to={urls.pl.it} activeClassName="active">Zaawansowane strony WWW</Link>
                        </div>
                        <div className={videoSubpageStyles.submenu_item}>
                          <Link to={urls.pl.it} activeClassName="active">Systemy firmowe</Link>
                        </div>
                        <div className={videoSubpageStyles.submenu_item}>
                          <Link to={urls.pl.it} activeClassName="active">Aplikacje mobilne</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={videoSubpageStyles.menu_item}>
                    <ScrollLink to="fpFooter" smooth={true} duration={500}>Kontakt</ScrollLink>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={videoSubpageStyles.page_content}>

          <div className={videoSubpageStyles.slogan}>
            <div className={videoSubpageStyles.content}>
              Nie jesteśmy Netflixem,<br/>
              ale mamy ich możliwości.
            </div>
          </div>
          <div/>


          <div className={videoSubpageStyles.items_row}>
            <div className={videoSubpageStyles.title}>Nasze produkcje</div>
            <div className={videoSubpageStyles.content}>
              {videos.map(video_data => {
                return (
                  <div className={videoSubpageStyles.item} onClick={() => this.openPreview(video_data)}>
                    <img src={video_data.thumbnail}/>
                    <div className={videoSubpageStyles.item_title}>{video_data.title}</div>
                  </div>
                )
              })}
            </div>
          </div>

          <div className={videoSubpageStyles.items_row}>
            <div className={videoSubpageStyles.title}>Nasza marka (YouTube) - Globalista TV</div>
            <div className={videoSubpageStyles.content}>
              {videosGlobalista.map(video_data => {
                return (
                  <div className={videoSubpageStyles.item} onClick={() => this.openPreview(video_data)}>
                    <img src={video_data.thumbnail}/>
                    <div className={videoSubpageStyles.item_title}>{video_data.title}</div>
                  </div>
                )
              })}
            </div>
          </div>

          <div className={[videoSubpageStyles.img_bg_block].join(" ")}>
            <img src={droneBlack}/>

            <div className={videoSubpageStyles.content}>
              <div className={videoSubpageStyles.left_col}>
                {/*<img src={droneSky} />*/}
              </div>
              <div className={videoSubpageStyles.right_right}>
                <div className={videoSubpageStyles.title}>Nagrania z drona</div>
                <div className={videoSubpageStyles.text}>
                  W swojej ofercie posiadamy tworzenie filmów również za pomocą drona.
                  Jako jedni z nielicznych posiadamy licencję UAVO pozwalającą na przeprowadzanie
                  komercyjnych lotów.
                </div>
                <div className={videoSubpageStyles.text}>
                  W swoim asortymencie posiadamy:
                  <ul>
                    <li>Inspire 2 + Zenmuse X5S</li>
                    <li>Phantom 4 PRO</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div style={{ height: "5rem" }}/>

          {this.props.isMobile.isMobile ? null : (
            <Footer/>
          )}
        </div>

      </Layout>
    )
  }
}

export default connect(state => ({
  isMobile: state.isMobile,
  windowRatio: state.windowRatio,
  windowH: state.windowH,
  windowW: state.windowW,
}), null)(VideoPage)
