import React from "react"
import { connect } from "react-redux"

import {
  changeScrollPos,
  changeWindowW,
  changeWindowH,
  changeWindowRatio,
  changeIsMobile,
} from "src/store/reducers/sizes"
import "src/css/layout.css"

import subpageStyle from "src/css/subpage.module.css"
import gradsStyle from "src/css/gradients.module.css"
import priceListStyle from "src/css/price_list.module.css"
import websitesSubpageStyles from "src/css/website_subpage.module.css"


class Layout extends React.Component {

  constructor(props) {
    super(props)
  }

  scrollEvent() {
    this.props.dispatch(changeScrollPos(window.pageYOffset))
  }

  resizeEvent() {
    console.log("resize", window.innerWidth, window.innerHeight)
    this.props.dispatch(changeWindowW(window.innerWidth))
    this.props.dispatch(changeWindowH(window.innerHeight))
    this.props.dispatch(changeWindowRatio(window.innerWidth / window.innerHeight))
    this.props.dispatch(changeIsMobile(window.innerWidth < 800))
  }

  loadEvent() {
    this.props.dispatch(changeScrollPos(window.pageYOffset))
    this.props.dispatch(changeWindowW(window.innerWidth))
    this.props.dispatch(changeWindowH(window.innerHeight))
    this.props.dispatch(changeIsMobile(window.innerWidth < 800))
    this.props.dispatch(changeWindowRatio(window.innerWidth / window.innerHeight))
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollEvent.bind(this))
    window.addEventListener("resize", this.resizeEvent.bind(this))
    window.addEventListener("load", this.loadEvent.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollEvent.bind(this))
    window.removeEventListener("resize", this.resizeEvent.bind(this))
    window.removeEventListener("load", this.loadEvent.bind(this))
  }

  render() {
    let className = this.props.isMobile.isMobile ? [
      subpageStyle.mobile,
      gradsStyle.mobile,
      priceListStyle.mobile,
      websitesSubpageStyles.mobile
    ].join(" ") : ""
    return (
      <div className={"layout " + className} onLoad={this.loadEvent.bind(this)} id="layout" style={this.props.style}>
        {this.props.children}
      </div>
    )
  }
}

export default connect(state => ({
  scrollPos: state.scrollPos,
  isMobile: state.isMobile,
}), null)(Layout)
