import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import depLogoWhite from "src/images/logo-whiteBorder.png"

import fpStyles from "src/css/fp-new.module.css"


export default class Footer extends React.Component {
  constructor(props) {
    super(props)

    this.fpFooterObj = React.createRef()
  }

  render() {
    let classes = [fpStyles.fp_footer]
    if (this.props.onLandingPage) classes.push(fpStyles.on_landing_page)
    return (
      <div className={classes.join(" ")} ref={this.fpFooterObj} style={this.props.style} id="fpFooter">
        <div className={fpStyles.fp_footer_content}>
          <div className={fpStyles.logo}>
            <img src={depLogoWhite}/>
          </div>
          <div className={fpStyles.contact}>
            <div className={fpStyles.title}>KONTAKT</div>
            <div className={fpStyles.content}>
              <div>Departament Reklamy</div>
              <div>AST Bednarscy</div>
              <div>ul. Widokowa 25</div>
              <div>26-050 Zagnańsk</div>
              <div style={{ paddingTop: ".5rem" }}>Os. Na Stoku 72K</div>
              <div>25-437 Kielce, ŚW, Polska</div>
              <div style={{ paddingTop: ".5rem" }}>+48 502 551 001</div>
              <div>+48 512 091 087</div>
              <div>kontakt@departamentreklamy.pl</div>
            </div>
          </div>
          <div className={fpStyles.footer_menu}>
            <div className={fpStyles.title}>Nasze specjalności</div>
            <div className={fpStyles.content}>
              <table>
                <tbody>
                <tr>
                  <td>
                    <div>Wideo</div>
                    <div>Oznakowanie pojazdów</div>
                    <div>Strony WWW</div>
                  </td>
                  <td>
                    <div>Zaawansowane strony WWW</div>
                    <div>Systemy firmowe</div>
                    <div>Aplikacje mobilne</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>Gadżety</div>
                    <div>Wydruki wielkoformatowe</div>
                    <div>Ulotki / Wizytówki</div>
                    <div>Reklama zewnętrzna</div>
                  </td>
                  <td>
                    <div>Stworzenie marki</div>
                    <div>Kampanie reklamowe</div>
                    <div>Opieka Social Media</div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Footer.propTypes = {
  style: PropTypes.object,
  onLandingPage: PropTypes.bool
}

Footer.defaultProps = {
  onLandingPage: false
}
