

export default {
  pl: {
    index: "/",
    branding: "/oferta/stworzenie-marki/",
    gadgets: "/oferta/gadzety-reklamowe/",
    papers: "/oferta/ulotki-wizytowki/",
    outside: "/oferta/reklama-zewnetrzna/",
    marketing: "/oferta/kampanie-reklamowe/",
    websites: "/oferta/strony-www/",
    socialmedia: "/oferta/opieka-social-media/",
    wideo: "/oferta/wideo-spoty-reklamowe-nagrywanie-filmow-dron/",
    wrapping: "/oferta/oklejanie-oznakowanie-pojazdow/",
    printing: "/oferta/wydruki-wielkoformatowe/",
    it: "/oferta/zaawanswane-strony-www-systemy-firmowe-aplikacje-mobilne/",
  },
  en: {
    index: "/en/",
    createBrand: "/en/offer/brand-creating/",
  }
}